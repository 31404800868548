@import '~antd/dist/antd.css';

html, body, #root, #root > div, .App {
    margin: 0;
    width: 100%;
    min-height: 100%;
    /*height: 100%;*/
    font-family: 'Open Sans', sans-serif;
}

* {
    box-sizing: border-box;
}

.ant-form-item-label > label::after {
    margin: 0 4px 0 2px;
    opacity: 0;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib, a.mapboxgl-ctrl-logo {
    display: none;
}

.ant-table-wrapper {
    overflow-x: auto;
}